<template>
	<div id="home">
		<section id="heroContent">
			<img src="@/assets/BE_Mockups@2x.png" class="heroImage" />
			<div id="heroText">
				<h1 class="main1" :class="{ 'text-gradient': !isMobile }">
					Berg Enterprises
				</h1>
				<h2 class="heroHeadline" :class="{ 'text-gradient': !isMobile }">
					Simplicity as a Service, and a Design to Match
				</h2>
			</div>
			<div class="visit-site-cont">
				<a
					class="continue firstButton"
					href="https://www.berg.enterprises/"
					target="blank"
					>Visit the Live Site
				</a>
			</div>
		</section>
		<section id="mainBody">
			<div class="overview-cont">
				<h3 class="text-gradient">Project Overview</h3>
				<div class="paragraph-cont">
					<p>
						Berg Enterprises is an Information Technology services provider for the
						Twin Cities Metro area in Minnesota. With an emphasis in keeping things
						simple for customers, it only seemed fitting that new marketing assets
						would embody the same simplicity. Careful attention to animation timing
						and real-time form validation and submission gives the user a fluid,
						seamless experience when viewing Berg.Enterprises.
					</p>
					<p>
						Our involvement in this project included: modernizing the logo, designing
						business cards, designing a new website, developing said site for desktop,
						tablet, and mobile, and copywriting for the website. We used Vue.js as our
						framework of choice for the front-end application and Node.js with the
						Express framework for the back-end.
					</p>
				</div>
			</div>
			<div id="logoRefresh">
				<img
					src="@/assets/BELogoUpdated@2x.png"
					style="max-width: 257px; width: 50%; margin: 12px 10px 12px 20px"
				/>
				<img
					src="@/assets/BENewLogos@2x.png"
					style="max-width: 558px; width: 100%; margin: 12px 0px 12px 0px"
				/>
			</div>
			<div id="desktopVideoCont">
				<video autoplay muted loop playsinline class="desktopVideo">
					<source src="@/assets/video/BE-Desktop-Video.mp4" type="video/mp4" />
				</video>
			</div>
			<div id="mobileVideoAndTechUsed">
				<video autoplay muted loop playsinline class="mobileVideo">
					<source src="@/assets/video/BE-Mobile-Video.mp4" type="video/mp4" />
				</video>
				<div id="techUsedCont">
					<h3 class="tech-used-header text-gradient">Tech Used</h3>
					<div
						v-for="techUsed in techsUsed"
						:key="techUsed.alt"
						class="tech-used-pair"
					>
						<img :src="techUsed.img" class="tech-used-img" />
						<span class="tech-used-alt">{{ techUsed.alt }}</span>
					</div>
				</div>
			</div>
			<div id="businessCardsSection">
				<img src="@/assets/BE_BC_Front@2x.png" class="business-card-img" />
				<img src="@/assets/BE_BC_Back@2x.png" class="business-card-img" />
			</div>
			<div id="endButtonsCont">
				<div @click="smoothScrollTop()">
					<router-link class="continue firstButton" :to="{ name: 'Contact' }"
						>Contact Us</router-link
					>
				</div>
				<div @click="smoothScrollTop()">
					<router-link class="continue secondButton" :to="{ name: 'Portfolio' }"
						>Back to Portfolio</router-link
					>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
// Dependency Imports
import { computed } from 'vue';
import { mainStore } from '@/store/main';
import { smoothScrollTop } from '@/plugins/utilities';

// Component Imports

export default {
	name: 'PortBergEnterprises',
	components: {},

	setup() {
		// Injections

		// Component Data:
		const techsUsed = [
			{
				alt: 'Vue 3',
				img: require('@/assets/cardIcons/vuejs.svg'),
			},
			{
				alt: 'Node JS',
				img: require('@/assets/cardIcons/nodejs.svg'),
			},
			{
				alt: 'ES6+',
				img: require('@/assets/cardIcons/javascript.svg'),
			},
			{
				alt: 'CSS 3+',
				img: require('@/assets/cardIcons/css3.svg'),
			},
			{
				alt: 'HTML 5+',
				img: require('@/assets/cardIcons/html5.svg'),
			},
		];

		// Setup Returns
		return {
			isMobile: computed(() => {
				return mainStore.getState().isMobile;
			}),
			techsUsed,
			smoothScrollTop,
		};
	},
};
</script>

<style scoped>
#home {
	border: solid yellow 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	/* z-index: 10; */
}

#heroContent {
	min-height: 500px;
	height: auto;
	width: 100%;
	margin: 0px;
	z-index: 0;
	border: solid green 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 0;
}

.heroImage {
	max-width: 1200px;
	width: 100%;
	border: solid blue 0px;
}

#heroText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: solid yellow 0px;
	padding: 0px 0px 0px 0px;
	margin: 10px 0px 10px 0px;
	max-width: 800px;
	width: 100%;
}

#mainBody {
	margin: 60px 0px 0px 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#logoRefresh {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	border: solid yellow 0px;
	margin: 100px 0px 100px 0px;
}

#cards {
	margin: 60px 0px 0px 0px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.visit-site-cont {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px 0px 0px 0px;
}

.overview-cont {
	padding: 20px 40px 20px 40px;
	border-radius: 40px;
	background: rgba(255, 255, 255, 0.5);
	background: var(--card-gradient);
	box-shadow: var(--card-shadow);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 90%;
	max-width: 1100px;
	margin: 20px 0px 0px 0px;
}

p {
	text-align: left;
	font-size: 18px;
	margin: 20px 20px 20px 20px;
	color: var(--text-color);
}

#desktopVideoCont {
	padding: 20px 0px 50px 0px;
	margin: 0px 0px 20px 0px;
}

.desktopVideo {
	max-width: 1100px;
	width: 90%;
	border-radius: 16px;
	border: solid rgba(0, 0, 0, 0.2) 0px;
	box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
}

#mobileVideoAndTechUsed {
	margin: 0px 0px 20px 0px;
	padding: 50px 0px 50px 0px;
	width: 100%;
	max-width: 1000px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.mobileVideo {
	max-width: 340px;
	width: 70%;
	border-radius: 30px;
	border: solid rgba(0, 0, 0, 0.2) 0px;
	box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
	margin: 40px 40px 40px 40px;
}

#techUsedCont {
	max-width: 340px;
	width: 70%;
	min-height: 736px;
	border-radius: 30px;
	background: rgba(255, 255, 255, 0.5);
	background: var(--card-gradient);
	box-shadow: var(--card-shadow);
	margin: 40px 40px 40px 40px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.tech-used-header {
	margin: 50px 0px 20px 0px;
}

.tech-used-alt {
	color: var(--text-color);
}

.tech-used-pair {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	min-height: 80px;
	max-width: 230px;
	width: 100%;
	border: solid yellow 0px;
	margin: 20px 0px 20px 0px;
}

.tech-used-img {
	width: 60px;
	height: 60px;
	margin: 0px 30px 0px 30px;
}

.tech-used-alt {
}

.business-card-img {
	max-width: 409px;
	width: 80%;
	margin: 20px 40px 20px 40px;
	border-radius: 30px;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
}

#businessCardsSection {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 50px 0px 50px 0px;
}

#endButtonsCont {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 40px 0px 40px 0px;
	padding: 40px;
}

#bulletPoints {
	border: solid blue 0px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0px 0px 0px 0px;
}

#findOutMore {
	width: 100%;
	height: auto;
	min-height: 150px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 100px 0px 50px 0px;
}

.continue {
	width: 280px;
	height: 60px;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border-radius: 60px;
	margin: 20px;
	color: rgba(255, 255, 255, 0.9);
	background: linear-gradient(
		145deg,
		rgba(0, 172, 255, 1) 0%,
		rgba(179, 0, 255, 1) 100%
	);
	background-size: 200%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	transition: cubic-bezier(0.56, 0, 0.39, 2.5) 230ms;
}

.continue:hover {
	transform: translateY(-3px);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.continue:active {
	transform: translateY(2px);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.firstButton {
	background-position: 0px 0px;
}

.secondButton {
	background-position: 300px;
}

h1 {
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 26px;
	margin: -10px 0px -10px 0px;
	border: solid yellow 0px;
}

h1.main1 {
	font-size: clamp(10px, 11vw, 60px);
	color: rgba(255, 255, 255, 0.9);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
}

.text-gradient {
	background: linear-gradient(
		145deg,
		rgba(18, 178, 255, 1) 0%,
		rgba(197, 58, 255, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

h1.main2 {
	font-size: clamp(20px, 16vw, 90px);
}

h2.heroHeadline {
	font-size: clamp(8px, 6vw, 24px);
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.2) 0px 2px 18px;
	border: solid yellow 0px;
	width: 90%;
	max-width: 500px;
	font-weight: 500;
	margin: 10px 0px 0px 0px;
}

h3 {
	font-size: clamp(8px, 6vw, 30px);
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.2) 0px 2px 18px;
	border: solid yellow 0px;
	font-weight: 600;
	margin: 16px 0px 2px 20px;
}

/* Animations */

@keyframes heroImageBounce {
	0% {
		transform: translateY(-4px) rotate(0.5deg);
	}
	50% {
		transform: translateY(4px) rotate(-0.5deg);
	}
	100% {
		transform: translateY(-4px) rotate(0.5deg);
	}
}

@media only screen and (max-width: 800px) {
	.overview-cont {
		padding: 20px 20px 20px 20px;
	}
}
</style>
